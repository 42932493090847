<script setup lang="ts">
  const licencesEl = ref();

  const navigationStore = useNavigationStore();

  useIntersectionObserver(
    licencesEl,
    ([{ isIntersecting }]) => {
      const tab = navigationStore.navigationTabs.find((t) => t.link === 'licences');
      if (tab) {
        tab.isActive = isIntersecting;
      }
    },
    {
      rootMargin: '-120px 0px 0px 0px'
    }
  );
</script>

<template>
  <div ref="licencesEl" class="licences-container main-container">
    <div class="licences-text-container">
      <span id="licences" data-nav="Лицензии" class="licences-title scroll-normalize">Лицензии клиники</span>
      <span class="licences-text">Наша клиника имеет все необходимые лицензии, соответствующие государственным требованиям</span>
    </div>
    <ScrollCarousel
      item-gap="20px"
      :show-arrows="true"
      arrows-pos="-15px"
    >
      <img class="licence-img" draggable="false" src="~/assets/img/licences/licence-1.webp">
      <img class="licence-img" draggable="false" src="~/assets/img/licences/licence-2.webp">
      <img class="licence-img" draggable="false" src="~/assets/img/licences/licence-3.webp">
      <img class="licence-img" draggable="false" src="~/assets/img/licences/licence-4.webp">
      <img class="licence-img" draggable="false" src="~/assets/img/licences/licence-5.webp">
      <img class="licence-img" draggable="false" src="~/assets/img/licences/licence-6.webp">
    </ScrollCarousel>
  </div>
</template>

<style scoped lang="scss">
.licences-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
  /* padding: 40px 28px 40px 28px; */
  @media (max-width: 1023px) {
    gap: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    /* padding: 30px 28px 30px 28px; */
  }
}
.licences-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
.licences-title {
  color: #262633;
  text-align: center;
  font-family: 'Roboto';
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.085px;
  @media (max-width: 1023px) {
    font-size: 24px;
    line-height: 28px;
  }
}
.licences-text {
  color: #262633;
  text-align: center;
  font-feature-settings: 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  max-width: 572px;
}
.licence-img {
  width: 277px;
  height: 392px;
  border-radius: 15px;
  border: 1px solid #E4E7EF;
  @media (max-width: 1023px) {
    width: 200px;
    height: 283px;
  }
}
.scroll-normalize {
  scroll-margin-top: 160px;
  @media (max-width: 1023px) {
    scroll-margin-top: 120px;
  }
}
</style>
